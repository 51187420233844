import '../../css/project_page.css';

import gbcTitle from '../../img/mine/gbc_title.png';
import gbc0 from '../../img/gbc-screenshots/chad_sky_cloudy_sword_bomb.png';
import gbc1 from '../../img/gbc-screenshots/mad_chad.png';
import gbc2 from '../../img/gbc-screenshots/shopping.png';
import gbc3 from '../../img/gbc-screenshots/chad_evil_slime_fight.png';
import gbc4 from '../../img/gbc-screenshots/sunny_mountain.png';
import gbc5 from '../../img/gbc-screenshots/gigachad.png';

import { BackButton, ButtonBar, ExternalLinkButton } from '../reusable/buttons';
import { SlideObject, Slideshow } from '../reusable/slideshow';

// The Slides to present in the slideshow.
const slides = [
    new SlideObject(gbc0, 'Explore the world and collect helpful items!'),
    new SlideObject(gbc2, 'Collect runes and shop for new equipment!'),
    new SlideObject(gbc3, 'Fight back hordes of evil monsters!'),
    new SlideObject(gbc1, 'Rescue your mother!'),
    new SlideObject(gbc4, 'Explore the world!'),
    new SlideObject(gbc5, 'Become a man!')
];

/**
 * The page summarizing my project, "The Good, the Bad, and Chad!".\
 * This page can be found at "/good-bad-chad".
 * @returns {JSX.Element} The JSX component.
 */
const GoodBadChad = () => {
    return (
        <div className='project-page good-bad-chad'>
            <BackButton />
            <img
                src={gbcTitle}
                className='title-img'
                alt='The text "The Good, the Bad, and Chad!" in pixelated lettering.'
            />
            <h3>
                A single-player platformer adventure game that runs in the
                browser.
            </h3>
            <ButtonBar>
                <ExternalLinkButton href='https://goodbadchad.bigdevdog.com'>
                    Play now!
                </ExternalLinkButton>
                <ExternalLinkButton href='https://github.com/b1gd3vd0g/good-bad-chad-devin'>
                    Check out the code
                </ExternalLinkButton>
            </ButtonBar>
            <Slideshow slides={slides} />
            <table id='contributors'>
                <tbody>
                    <tr>
                        <td>
                            <b>Co-Contributors: </b>
                        </td>
                    </tr>
                    <tr>
                        <td>Trae Claar</td>
                        <td>
                            <a
                                href='https://github.com/tclaar'
                                target='_blank'
                                rel='noreferrer'
                            >
                                [Github]
                            </a>
                        </td>
                        <td>
                            <a
                                href='https://linkedin.com/in/trae-claar'
                                target='_blank'
                                rel='noreferrer'
                            >
                                [LinkedIn]
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Caleb Krauter</td>
                        <td>
                            <a
                                href='https://github.com/CalebKrauter'
                                target='_blank'
                                rel='noreferrer'
                            >
                                [Github]
                            </a>
                        </td>
                        <td>
                            <a
                                href='https://www.linkedin.com/in/calebkrauter'
                                target='_blank'
                                rel='noreferrer'
                            >
                                [LinkedIn]
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Nathan Hinthorne</td>
                        <td>
                            <a
                                href='https://github.com/NathanHinthorne'
                                target='_blank'
                                rel='noreferrer'
                            >
                                [Github]
                            </a>
                        </td>
                        <td>
                            <a
                                rel='noreferrer'
                                href='https://www.linkedin.com/in/nathan-hinthorne/'
                            >
                                [LinkedIn]
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Development Phases:</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <em>Initial phase:</em>
                        </td>
                        <td colSpan={2}>January - March 2024</td>
                    </tr>
                    <tr>
                        <td>
                            <em>Add save functionality:</em>
                        </td>
                        <td colSpan={2}>January 2025</td>
                    </tr>
                </tbody>
            </table>
            <p>
                You are Chad - a lazy boy from a tiny, peaceful village where
                nothing ever happens; that is, until your village is attacked by
                an evil wizard, and you must set out on an epic quest to rescue
                your kidnapped mother from his evil clutches! Armed with your
                trusty sword and slingshot, you must learn battle as you go,
                exploring a world much larger and more dangerous than you could
                have ever imagined!
            </p>
            <h4>Tech Overview</h4>
            <p>
                <i>The Good, the Bad, and Chad!</i> is a two-dimensional
                platformer adventure game that runs in the browser. Written in
                vanilla JavaScript, the game was developed from a heavily
                modified&nbsp;
                <a
                    href='https://github.com/algorithm0r/Empty--GameEngine'
                    target='_blank'
                    rel='noreferrer'
                >
                    custom game engine
                </a>
                &nbsp;and includes 100% original pixel art <i>and</i> music.
            </p>
            <p>
                During my upgrade in Jan 2025 adding saves, I added an in-game
                menu to make calls to a&nbsp;
                <a
                    href='https://github.com/b1gd3vd0g/good-bad-chad-saving-api'
                    rel='noreferrer'
                >
                    REST API
                </a>
                &nbsp;on top of a postgres database, allowing players to create
                accounts, login securely, and create, load, and delete save
                files. The game uses sessionStorage cookies (or localStorage,
                depending on player preference), to maintain JSON web tokens for
                secure authentication.
            </p>
            <h4>Personal Contributions</h4>
            <p>
                Whereas I familiarized myself with and contributed to the entire
                (very large) code base, my most notable contributions were as
                follows:
            </p>
            <ul>
                <li>
                    Created a simple, versatile system for dialog interactions
                    with NPCs, empowering functionality such as a&nbsp;
                    <i>merchant system</i> and&nbsp;
                    <i>forked story progression</i>.
                </li>
                <li>
                    Optimized the zone-loading scripts, ensuring the proper
                    assets are loaded quickly, and no-longer necessary assets
                    are removed from memory.
                </li>
                <li>
                    Reduce time cost of collision checking dramatically, by
                    introducing the concepts of foreground, midground, and
                    background to existing entity array.
                </li>
                <li>
                    Implement a more advanced in-game pause menu, making calls
                    to the REST API, enabling players to create accounts,
                    securely log in, and create, load, and delete save files.
                </li>
            </ul>
        </div>
    );
};

export default GoodBadChad;
