import bddHead from '../img/mine/bdd_head_sprite.png';
import bddLogo from '../img/mine/logo_trans_celadon.png';
import github from '../img/external/github_celadon.png';
import linkedin from '../img/external/linkedin_celadon.png';
import { Link } from 'react-router-dom';
import '../css/header.css';

/**
 * The Header component is found at the top of each page in this app. It
 * contains my logos (which link to the homepage when clicked), and
 * link icons to my github and linkedin accounts.
 *
 * @returns {JSX.Element} The JSX component.
 */
const Header = () => {
    return (
        <header>
            <Link to='/'>
                <div id='my-logos'>
                    <img
                        src={bddHead}
                        alt="A pixelated representation of Devin Peevy's face - a light skinned man with brown hair and big glasses."
                    />
                    <img
                        id='bdd-txt'
                        src={bddLogo}
                        alt="The logo of Big Dev Dog: large capital letters 'BDD' to the left of a bar, to the right of which are the words 'BIG DEV DOG' stacked atop eah other in all caps."
                    />
                </div>
            </Link>
            <div>
                <a
                    href='https://github.com/b1gd3vd0g'
                    target='_blank'
                    rel='noreferrer'
                >
                    <img
                        src={github}
                        alt='A circular version of the github logo.'
                    />
                </a>
                <a
                    href='https://linkedin.com/in/devinpeevy'
                    target='_blank'
                    rel='noreferrer'
                >
                    <img
                        src={linkedin}
                        alt='A circular version of the linkedin logo.'
                    />
                </a>
            </div>
        </header>
    );
};

export default Header;
