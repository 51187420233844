import '../css/home_page.css';

import selfie from '../img/mine/selfie.jpg';
import nodeLogo from '../img/abilities/nodereact.png';
import dbLogos from '../img/abilities/dbs.png';
import { ButtonBar, InternalLinkButton } from './reusable/buttons';
import { SlideObject, Slideshow } from './reusable/slideshow';

const abilitySlides = [
    new SlideObject(
        nodeLogo,
        'I can create full-stack web applications, creating user-friendly webpages using React, and backend web services using NodeJS.'
    ),
    new SlideObject(
        dbLogos,
        "I can utilize both relational (SQL) and non-relational databases to securely store and access an application's data."
    )
];

/**
 * The HomePage is found at the root path, and contains an introduction
 * to myself and the site. It contains links to the other parts of my site.
 * @returns {JSX.Element} The JSX component.
 */
const HomePage = () => {
    return (
        <div className='home-page'>
            <Intro />
            <Abilities />
        </div>
    );
};

const Intro = () => {
    return (
        <div className='intro'>
            <img
                src={selfie}
                alt='Devin Peevy, a young man with thick-rimmed black glasses and a smirk.'
            />
            <div>
                <h1>Hi, I'm Devin.</h1>
                <p>
                    I'm a software developer from Washington state with a
                    passion for web and game development. This is my portfolio
                    website, where I showcase a collection of my various
                    projects.
                </p>
                <p>
                    This site is still under construction - new additions will
                    be here <i>very soon</i>! Follow its development on&nbsp;
                    <a
                        href='https://github.com/b1gd3vd0g/bdd-com'
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub
                    </a>
                    !
                </p>
                <ButtonBar>
                    <InternalLinkButton to='/projects'>
                        Projects
                    </InternalLinkButton>
                </ButtonBar>
            </div>
        </div>
    );
};

const Abilities = () => {
    return (
        <div className='abilities'>
            <h1>What can I do?</h1>
            <Slideshow slides={abilitySlides} />
        </div>
    );
};

export default HomePage;
