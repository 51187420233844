import tkLogo from '../../img/mine/timekeeper_logo.png';
import { BackButton, ButtonBar, ExternalLinkButton } from '../reusable/buttons';
import '../../css/project_page.css';

const Timekeeper = () => {
    return (
        <div className='project-page timekeeper'>
            <BackButton />
            <img
                src={tkLogo}
                className='title-img'
                alt='The logo for Timekeper: a clock and a dollar coin.'
            />
            <h3>
                A time-logging application allowing users to track their time
                spent on certain projects, potentially sharing detailed billing
                information to clients.
            </h3>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <b>Initial Development Phase:</b>
                        </td>
                        <td>February 2025 - Present</td>
                    </tr>
                </tbody>
            </table>
            <h4>
                <em>NOTE: </em> This project is still in its early development
                stages. Keep checking back for more information, as updates are
                coming soon!
            </h4>
            <p>
                Timekeeper allows users to easily keep track of their time spent
                working. A single user may keep track of their work on several
                projects at once. In the case of projects which are being
                completed for a (presumably paying) client, detailed information
                can be shared with the client, as well as summarized billing
                information.
            </p>
        </div>
    );
};

export default Timekeeper;
