import backArrow from '../../img/misc/left_arrow.png';
import { Link, useLocation } from 'react-router-dom';
import '../../css/buttons.css';

const BackButton = () => {
    const location = useLocation().pathname;
    let lastSlash = 0;
    for (let i = 0; i < location.length; i++) {
        if (location.charAt(i) === '/') {
            lastSlash = i;
        }
    }
    const to = location.slice(0, Math.max(1, lastSlash));
    return (
        <Link to={to}>
            <img
                src={backArrow}
                className='back-button'
                alt='An left pointing arrow'
            />
        </Link>
    );
};

/**
 * A button bar is simply a div which is placed around a series of Buttons to
 * present them in a horizontal line.
 * @param {object} props The props associated with this button bar.
 * @param {[JSX.Element]} children The children components.
 * @returns {JSX.Element} The JSX component.
 */
const ButtonBar = ({ children }) => {
    return <div className='button-bar'>{children}</div>;
};

/**
 * A clickable div which opens an external link identified by the href attribute.
 *
 * @param {object} props The props associated with this object.
 * @param {[JSX.Element]} props.children The chidlren of this button.
 * @param {string} props.href The URL of the site to be opened.
 * @returns {JSX.Element} The JSX component.
 */
const ExternalLinkButton = ({ children, href, dark = false }) => {
    return (
        <div className='button-wrapper'>
            <a
                href={href}
                target='_blank'
                rel='noreferrer'
            >
                <div className='button'>{children}</div>
            </a>
        </div>
    );
};

/**
 * A clickable div which navigates to a different page within this app.
 *
 * @param {object} props The props associated with this button.
 * @param {[JSX.Element]} props.children The children of this button.
 * @param {string} props.to The path to the desired page (excluding hostname!).
 * @returns {JSX.Element} The JSX component.
 */
const InternalLinkButton = ({ children, to, dark = false }) => {
    return (
        <div className='button-wrapper'>
            <Link to={to}>
                <div className={`${dark ? 'dark ' : ''}button`}>{children}</div>
            </Link>
        </div>
    );
};

export { BackButton, ButtonBar, ExternalLinkButton, InternalLinkButton };
