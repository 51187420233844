import { useState } from 'react';
import rightArrow from '../../img/misc/right_arrow.png';
import leftArrow from '../../img/misc/left_arrow.png';

import '../../css/slideshow.css';

/**
 * Contains the information that belongs on a single slide of a
 * Slideshow component.
 */
class SlideObject {
    /**
     * @param {HTMLImageElement} img The image to be displayed on this slide.
     * @param {string} caption The caption to be displayed under the image.
     * @param {string} alt The alt text for the image.
     */
    constructor(img, caption, alt = '') {
        /** The image to be displayed on this slide. */
        this.img = img;
        /** The caption to be displayed on this slide. */
        this.caption = caption;
        /** The alt text to go with the image. */
        this.alt = alt;
    }
}

/**
 * A Slideshow includes multiple SlideObjects, showing a single one at a time.
 * It can be iterated through by the user.
 *
 * @param {object} props - An object containing the component's props.
 * @param {[SlideObject]} props.slides - Each slide to be included in this slideshow.
 * @returns {JSX.Element} - The rendered component
 */
const Slideshow = ({ slides }) => {
    const [index, setIndex] = useState(0);
    const currentSlide = slides[index];

    return (
        <>
            <div className='slideshow'>
                <img
                    src={leftArrow}
                    onClick={() => {
                        setIndex(index === 0 ? slides.length - 1 : index - 1);
                    }}
                    className='arrow'
                    alt='A left facing arrow.'
                />
                <div className='slide'>
                    <img
                        src={currentSlide.img}
                        alt={currentSlide.alt}
                    />
                    <p>{currentSlide.caption}</p>
                </div>
                <img
                    src={rightArrow}
                    onClick={() => {
                        setIndex(index === slides.length - 1 ? 0 : index + 1);
                    }}
                    className='arrow'
                    alt='A right facing arrow.'
                />
            </div>
            <DotBar
                ctx={{ index, setIndex }}
                count={slides.length}
            />
        </>
    );
};

/**
 * A Dot Bar is displayed beneath a Slideshow, and summarizes to the user the
 * total number of slides and the currently active slide.
 *
 * @param {object} props - An object containing the props.
 * @param {number} props.count - The number of slides in the slideshow.
 * @param {object} props.ctx - An object containing the index and setIndex of
 * the associated slideshow.
 * @param {number} props.ctx.index - The index of the associated slideshow.
 * @param {function} props.ctx.setIndex - The index setter of the associated slideshow.
 * @returns {JSX.Element} - The JSX component.
 */
const DotBar = ({ count, ctx }) => {
    const dots = [];
    for (let i = 0; i < count; i++) {
        const active = ctx.index === i ? 'active ' : '';
        dots.push(
            <div
                className={`${active}dot`}
                onClick={() => {
                    ctx.setIndex(i);
                }}
                key={`dot_bar_dot_${i}`}
            ></div>
        );
    }
    return <div className='dot-bar'>{dots}</div>;
};

export { SlideObject, Slideshow };
