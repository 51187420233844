import { Link } from 'react-router-dom';

/**
 * This page is shown whenever a user navigates to a path which is not defined
 * in the BrowserRouter found in `src/components/app`.
 * @returns {JSX.Element} The JSX component
 */
const FourOhFour = () => {
    return (
        <div className='FourOhFour'>
            <h1>
                <b>404: </b>File not found.
            </h1>
            <Link to='/'>Return to home page.</Link>
        </div>
    );
};

export default FourOhFour;
