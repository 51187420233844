import gbcTitle from '../img/mine/gbc_title.png';
import tkTitle from '../img/mine/timekeeper_logo.png';
import { BackButton, InternalLinkButton } from './reusable/buttons';

import '../css/projects.css';

/** An object containing the vital information for a ProjectSummary. */
class PSum {
    constructor(name, span, image, text, path) {
        this.name = name;
        this.span = span;
        this.image = image;
        this.text = text;
        this.path = path;
    }
}

/** An array of all featured PSums. */
const projects = [
    new PSum(
        'The Good, the Bad, and Chad!',
        'Jan 2024 - Jan 2025',
        gbcTitle,
        'A single-player adventure platformer game written in JavaScript which runs in the browser.',
        '/projects/good-bad-chad'
    ),
    new PSum(
        'Timekeeper',
        'Feb 2025 - Present',
        tkTitle,
        'A time management web application written in JavaScript using React, NodeJS, and PostgreSQL.',
        '/projects/timekeeper'
    )
];

/**
 * The Projects Page, featuring a list of notable project summaries, which all
 * link to actual pages going more in-depth into the project.
 * @returns {JSX.Element} The JSX Component.
 */
const Projects = () => {
    let i = -1;
    const components = projects.map((proj) => {
        i++;
        return (
            <ProjectSummary
                project={proj}
                key={`proj-summary-${i}`}
            />
        );
    });

    return (
        <div className='projects'>
            <BackButton />
            <h1>Projects</h1>
            {components}
        </div>
    );
};

/**
 * A Project Summary is a box which highlights key features of a project, and
 * provides a link to a page which further discusses said project.
 * @param {object} props The props object.
 * @param {PSum} props.project The PSum object containing all
 * necessary information for this Project Summary box.
 * @returns {JSX.Element} The JSX Component.
 */
const ProjectSummary = ({ project }) => {
    return (
        <div className='project-summary'>
            <img
                src={project.image}
                alt=''
            />
            <div>
                <h2>{project.name}</h2>
                <h3>{project.span}</h3>
                <p>{project.text}</p>
                <InternalLinkButton
                    to={project.path}
                    dark={true}
                >
                    Check it out
                </InternalLinkButton>
            </div>
        </div>
    );
};

export default Projects;
