import { BrowserRouter, Routes, Route } from 'react-router-dom';

import FourOhFour from './404';
import GoodBadChad from './project-pages/good_bad_chad';
import HomePage from './home_page';
import Header from './header';
import Timekeeper from './project-pages/timekeeper';

import '../css/app.css';
import Projects from './projects';

/**
 * The App is the outermost component. It contains the BrowserRouter, and
 * handles the consistent layout found on every page.
 * @returns {JSX.Element} The JSX component.
 */
const App = () => {
    return (
        <div className='app'>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route
                        path='/'
                        element={<HomePage />}
                    />
                    <Route
                        path='/projects'
                        element={<Projects />}
                    />
                    <Route
                        path='/projects/good-bad-chad'
                        element={<GoodBadChad />}
                    />
                    <Route
                        path='/projects/timekeeper'
                        element={<Timekeeper />}
                    />
                    <Route
                        path='*'
                        element={<FourOhFour />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
